import { createContext, useState } from "react";
import { CONST_GENDER_MALE } from "../helpers";

export const ContextProfileUser = createContext(null);

export const ProfileUserProvider = (props) => {
  const [data, setData] = useState({ gender: CONST_GENDER_MALE.toString() });
  const { children } = props;

  return (
    <ContextProfileUser.Provider value={{ data, setData }}>
      {children}
    </ContextProfileUser.Provider>
  );
};
