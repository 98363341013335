import { useContext, useState } from "react";
import "./nameForm.scss";
import { simplyClick, validateName } from "../../helpers";
import ButtonSubmit from "../buttonSubmit";
import InputGlobal from "../inputGlobal";
import { errorEventsName } from "../../constants";
import { ContextProfileUser } from "../../lib/profileContext";

function NameForm({ nextStep }) {
  const [errorName, setErrorName] = useState(null);
  const { data, setData } = useContext(ContextProfileUser);

  const handlerSubmit = (e) => {
    e.preventDefault();

    const { status, error = { text: "", type: "" } } = validateName(
      data?.first_name ?? "",
    );

    if (error.type) {
      simplyClick(errorEventsName[error.type], null);
    }

    if (!status) {
      setErrorName(error.text);
    } else {
      simplyClick("cl_lnd_lt2_name_next", { first_name: data?.first_name });
      nextStep();
    }
  };

  const handleAddName = (e) => {
    setErrorName(null);
    setData({ ...data, first_name: e.target.value });
  };

  return (
    <div className="name__form-wrapper">
      <div className="user__form-top">
        <h3>My name</h3>
      </div>
      <form className="form__name" onSubmit={handlerSubmit} autoComplete="off">
        <InputGlobal
          onChange={(e) => handleAddName(e)}
          placeholder={"Type your name"}
          className={"input__global input__name"}
          name={"name__lt2"}
          type={"text"}
          autoComplete={"name"}
        />
        {errorName && <p className="errorName">{errorName}</p>}
        <ButtonSubmit className={"button__global button__name"} text={"NEXT"} />
        <div className="progress__dots">
          <span className="active"></span>
          <span className="active"></span>
          <span className="active"></span>
          <span className="active"></span>
          <span></span>
          <span></span>
        </div>
      </form>
    </div>
  );
}

export default NameForm;
