import React from "react";
import ReactDOM from "react-dom";
import "./css/index.scss";
import App from "./App";

//Context
import { ProfileUserProvider } from "./lib/profileContext";
import { getTTI } from "./helpers";

getTTI();

ReactDOM.render(
  <React.StrictMode>
    <ProfileUserProvider>
      <App />
    </ProfileUserProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
