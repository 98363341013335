import React, { useContext, useState } from "react";
import "./emailForm.scss";
import { simplyClick, validateEmail } from "../../helpers";
import ButtonSubmit from "../buttonSubmit";
import InputGlobal from "../inputGlobal";
import {
  errorEventsEmail,
  IS_VISIBLE_CUSTOM_AUTOCOMPLETE,
} from "../../constants";
import CustomEmailAutoComplete from "../CustomEmailAutoComplete";
import { ContextProfileUser } from "../../lib/profileContext";

function EmailForm({ nextStep }) {
  const [errorEmail, setErrorEmail] = useState(null);
  const [loader, setLoader] = useState(false);
  const { data, setData } = useContext(ContextProfileUser);

  const handlerSubmit = (e) => {
    e.preventDefault();
    setLoader(true);

    validateEmail(data.email, (result) => {
      const { status, error } = result;
      setLoader(false);
      if (!status && error) {
        const analyticsEvent = errorEventsEmail[error];
        if (analyticsEvent) {
          simplyClick(analyticsEvent, null);
        }
        setErrorEmail(error);
        return;
      }
      nextStep();
    });
  };

  const handleAddEmail = (e) => {
    setEmailValue(e.target.value);
  };

  const setEmailValue = (value) => {
    setErrorEmail(null);
    setData({ ...data, email: value });
  };

  return (
    <>
      {loader && (
        <div className="spin-loader">
          <span>Loading...</span>
        </div>
      )}
      <div className="email__form-wrapper">
        <div className="user__form-top">
          <h3>My email</h3>
        </div>
        <form
          className="form__email"
          onSubmit={handlerSubmit}
          autoComplete="off"
        >
          <div className="input__wrapper">
            <InputGlobal
              onChange={(e) => handleAddEmail(e)}
              placeholder={"Type your email"}
              className={"input__global input__email"}
              name={"email"}
              type={"text"}
              value={data.email ? data.email : ""}
              autoComplete="on"
            />
            {IS_VISIBLE_CUSTOM_AUTOCOMPLETE && (
              <CustomEmailAutoComplete
                errorEmail={errorEmail}
                errorCb={() => setErrorEmail(null)}
              />
            )}
          </div>

          {errorEmail && <p className="errorEmail">{errorEmail}</p>}
          <ButtonSubmit
            className={"button__global button__email"}
            text={"NEXT"}
          />
          <div className="progress__dots">
            <span className="active"></span>
            <span className="active"></span>
            <span className="active"></span>
            <span className="active"></span>
            <span className="active"></span>
            <span></span>
          </div>
        </form>
      </div>
    </>
  );
}

export default EmailForm;
