import React from "react";
import "./PromoStep.scss";
import ButtonSubmit from "../buttonSubmit";
import { simplyClick } from "../../helpers";

function PromoStep({ nextStep }) {
  return (
    <div className="promo__wrapper">
      <div className="user__form-top">
        <h3>Get your dream match</h3>
      </div>
      <div className="user__form-content">
        <img src="assets/images/girl-promo.png" alt="image-girl" />
        <h4>Important!</h4>
        <div className="text__wrapper">
          <p>
            This is the place where all of the members’ personal information is
            protected.
          </p>
          <p>And you also have to respect the privacy of fellow members.</p>
        </div>

        <ButtonSubmit
          onClick={() => {
            simplyClick("cl_lnd_lt2_match_me", null);
            nextStep();
          }}
          className={"button__global button__promo"}
          text={"MATCH ME "}
          dataAttr={"cookies"}
        />
        <div className="progress__dots">
          <span className="active"></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
}

export default PromoStep;
