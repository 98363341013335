export const consentPropertyType = "site_consent";
export const cookieConsentPropertyType = "CookieConsent";

export const errorEventsName = {
  empty: "err_lnd_name_noname",
  too_short: "err_lnd_name_shortname",
  invalid_chars: "err_lnd_name_symbolname",
};

export const errorEventsBirthday = {
  M: "err_lnd_age_onlymonth",
  D: "err_lnd_age_onlyday",
  Y: "err_lnd_age_onlyyear",
  MD: "err_lnd_age_onlydaymonth",
  DY: "err_lnd_age_onlydayyear",
  MY: "err_lnd_age_onlymonthyear",
};

export const errorEventsEmail = {
  "This field cannot be empty.": "err_lnd_email_noemail",
  "Please enter a correct email address.": "err_lnd_email_wrongemail",
};
export const ERROR_EMAIL_ALREADY_USED =
  "This email address has already been used.";

export const errorEventsPassword = {
  empty: "err_lnd_password_nopassword",
  too_short: "err_lnd_password_shortpassword",
};

export const IS_VISIBLE_CUSTOM_AUTOCOMPLETE = false;

export const domains = [
  "@gmail.com",
  "@yahoo.com",
  "@hotmail.com",
  "@aol.com",
  "@outlook.com",
  "@comcast.net",
  "@live.com",
  "@mail.com",
  "@msn.com",
  "@att.net",
  "@icloud.com",
];
